var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('Search',{attrs:{"search-params":_vm.searchParamsObject},on:{"update:searchParams":function (val, index) { _vm.searchParamsObject[index].value = val },"button-event":_vm.searhPanelButtonEvent}}),_c('b-card',[_c('vxe-table',{ref:"xTable",attrs:{"border":"","auto-resize":true,"data":_vm.tableData,"highlight-current-row":"","cell-class-name":_vm.cellClassName,"loading":_vm.loading,"scroll-x":{enabled: true},"scroll-y":{enabled: true}}},[_c('vxe-table-column',{attrs:{"field":"id","title":"ID","width":"80"}}),_c('vxe-table-column',{attrs:{"field":"title","title":"海报标题","min-width":"200","show-overflow":""}}),_c('vxe-table-column',{attrs:{"field":"srcUrl","title":"海报原图","width":"120","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('viewer',[_c('b-img',{staticClass:"d-inline-block",attrs:{"src":row.srcUrl,"height":"35","width":"auto"}})],1)]}}])}),_c('vxe-table-column',{attrs:{"field":"composeUrl","title":"海报合成图","width":"120","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('viewer',[_c('b-img',{staticClass:"d-inline-block",attrs:{"src":row.composeUrl,"height":"35","width":"auto"}})],1)]}}])}),_c('vxe-table-column',{attrs:{"field":"categoryName","title":"海报分类","width":"150","show-overflow":""}}),_c('vxe-table-column',{attrs:{"field":"staffName","title":"员工姓名","width":"120","show-overflow":""}}),_c('vxe-table-column',{attrs:{"field":"verifyRemark","title":"审核备注","min-width":"250","show-overflow":""}}),_c('vxe-table-column',{attrs:{"field":"status","title":"上架状态","width":"120","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('b-badge',{staticClass:"px-1 py-50",attrs:{"pill":"","variant":row.status ? 'light-primary' : 'light-warning'}},[_vm._v(" "+_vm._s(row.status ? '上架中' : '下架中')+" ")])]}}])}),_c('vxe-table-column',{attrs:{"field":"verifyStatus","title":"审核状态","width":"120","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [(row.verifyStatus === 1)?_c('b-badge',{staticClass:"px-1 py-50",attrs:{"pill":"","variant":"light-primary"}},[_vm._v(" 已审核 ")]):(row.verifyStatus === -1)?_c('b-badge',{staticClass:"px-1 py-50",attrs:{"pill":"","variant":"light-warning"}},[_vm._v(" 不通过 ")]):_c('b-badge',{staticClass:"px-1 py-50",attrs:{"pill":"","variant":"light-success"}},[_vm._v(" 未审核 ")])]}}])}),_c('vxe-table-column',{attrs:{"field":"action","title":"操作","align":"center","width":"120","fixed":"right"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('table-action-panel',{attrs:{"rowName":row.id,"extraData":row,"menu":_vm.setMenuDisabled(row)},on:{"click-event":_vm.tableActionEvent}})]}}])})],1),_c('page-set-panel',{attrs:{"page":_vm.searchParams.page,"size":_vm.searchParams.size,"total":_vm.total},on:{"update:page":function($event){return _vm.$set(_vm.searchParams, "page", $event)},"update:size":function($event){return _vm.$set(_vm.searchParams, "size", $event)}}})],1),_c('Verify',{attrs:{"extra":_vm.verifyExtra},on:{"up-verify":_vm.verifyEvent},model:{value:(_vm.shallShowModalVerify),callback:function ($$v) {_vm.shallShowModalVerify=$$v},expression:"shallShowModalVerify"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }