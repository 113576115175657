<template>
  <div>
    <!-- 演示自动生成查询条件组件，根据接口查询的条件，自动生成对应的查询面板 -->
    <Search
      :search-params="searchParamsObject"
      @update:searchParams="(val, index) => { searchParamsObject[index].value = val }"
      @button-event="searhPanelButtonEvent"
    />

    <b-card>
      <!-- table -->
      <vxe-table
        ref="xTable" border :auto-resize="true"
        :data="tableData" highlight-current-row
        :cell-class-name="cellClassName"
        :loading="loading" :scroll-x="{enabled: true}"
        :scroll-y="{enabled: true}"
      >
        <vxe-table-column field="id" title="ID" width="80"></vxe-table-column>
        <vxe-table-column field="title" title="海报标题" min-width="200" show-overflow></vxe-table-column>
        <vxe-table-column field="srcUrl" title="海报原图" width="120" align="center">
          <template v-slot="{ row }">
            <viewer><b-img :src="row.srcUrl" height="35" width="auto" class="d-inline-block" /></viewer>
          </template>
        </vxe-table-column>
        <vxe-table-column field="composeUrl" title="海报合成图" width="120" align="center">
          <template v-slot="{ row }">
            <viewer><b-img :src="row.composeUrl" height="35" width="auto" class="d-inline-block" /></viewer>
          </template>
        </vxe-table-column>

        <vxe-table-column field="categoryName" title="海报分类" width="150" show-overflow></vxe-table-column>
        <vxe-table-column field="staffName" title="员工姓名" width="120" show-overflow></vxe-table-column>
        <vxe-table-column field="verifyRemark" title="审核备注" min-width="250" show-overflow></vxe-table-column>
        <vxe-table-column field="status" title="上架状态" width="120" align="center">
          <template v-slot="{ row }">
            <b-badge pill :variant="row.status ? 'light-primary' : 'light-warning'" class="px-1 py-50">
              {{ row.status ? '上架中' : '下架中' }}
            </b-badge>
          </template>
        </vxe-table-column>
        <vxe-table-column field="verifyStatus" title="审核状态" width="120" align="center">
          <template v-slot="{ row }">
            <b-badge pill v-if="row.verifyStatus === 1" variant="light-primary" class="px-1 py-50"> 已审核 </b-badge>
            <b-badge pill v-else-if="row.verifyStatus === -1" variant="light-warning" class="px-1 py-50"> 不通过 </b-badge>
            <b-badge pill v-else variant="light-success" class="px-1 py-50"> 未审核 </b-badge>
          </template>
        </vxe-table-column>

        <vxe-table-column field="action" title="操作" align="center" width="120" fixed="right">
          <template v-slot="{ row }">
            <table-action-panel
              :rowName="row.id"
              :extraData="row"
              :menu="setMenuDisabled(row)"
              @click-event="tableActionEvent"
            />
          </template>
        </vxe-table-column>
      </vxe-table>

      <page-set-panel
        :page.sync="searchParams.page"
        :size.sync="searchParams.size"
        :total="total"
      />
    </b-card>

    <Verify 
      @up-verify="verifyEvent"
      v-model="shallShowModalVerify"
      :extra="verifyExtra"
    />
  </div>
</template>

<script>

// 查询参数配置组件
import TableActionPanel from '@/comps/TableActionPanel'
// 页码组件
import PageSetPanel from '@/comps/PageSetPanel'
// 导入接口函数
import useFunction from './useFunction'
// 查询组件自动生成
import Search from '@/comps/Search'
// 查询组件自动生成
import Verify from '@/comps/Verify'

import Ripple from 'vue-ripple-directive'

export default {
  directives: {
    Ripple,
  },
  components: {
    PageSetPanel,
    TableActionPanel,

    Search,
    Verify,
  },
  setup(props, { root }) {
    // 应用框架 必要组件 $can 权限判断
    const { $can } = root

    /* 接口调用，抽离参数，函数等 */
    const {
      moduleText,
      // 主事件控制区
      buttonLeftOptions,
      buttonRightOptions,
      mainActionEvent,
      // 表格呈现
      cellClassName,
      loading,
      tableData,
      xTable,
      // 表格操作菜单过滤
      setMenuDisabled,
      // 表格事件分发
      tableActionEvent,

      // 查询模块
      searchParams,
      searchParamsObject,
      //查询操作
      searhPanelButtonEvent,
      total,

      verifyEvent,

      shallShowModalVerify,
      ziyuanVerify,
      verifyExtra,

      // 编辑对话框
      // blankEditParams,
      // editValue,
      // editEvent,
      // isShowSidebarActive,
    } = useFunction($can)


    return {
      moduleText,
      // 主事件控制区
      mainActionEvent,
      buttonLeftOptions,
      buttonRightOptions,

      // 表格呈现
      cellClassName,
      verifyEvent,
      loading,
      tableData,
      xTable,
      // 表格操作菜单过滤
      setMenuDisabled,
      // 表格事件分发
      tableActionEvent,

      // 查询组件事件分发
      searhPanelButtonEvent,

      // 查询模块
      searchParams,
      searchParamsObject,
      total,

      shallShowModalVerify,
      ziyuanVerify,
      verifyExtra,

      // 编辑对话框
      // blankEditParams,
      // editValue,
      // editEvent,
      // isShowSidebarActive,
    }
  },
}
</script>
<style lang="scss">
// @import '@core/scss/vue/libs/vue-sweetalert.scss';
</style>
